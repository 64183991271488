@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

html, body {
    height: 100%;
}

#root {
    min-height: 100%;
}

body {
    min-width: 300px;
}

.focused {
    background-color: aqua;
}

.mention {
    color: #0052CC;
    font-weight: 400;
}

#list-page-header {
    font-size: 1.3rem;
}